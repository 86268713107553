import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'
import { motion } from 'framer-motion'

const StyledButton = styled(motion.span)`
  display: inline-block;
  min-width: 240px;
  text-align: center;

  & > a,
  & > button {
    font-family: ${(props) => props.theme.font.family.secondary};
    background-color: ${(props) => props.theme.color.face.light};
    color: ${(props) => props.theme.color.text.dark};
    border: 1px solid ${(props) => props.theme.color.face.light};
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    border-radius: 5px;

    & :hover {
      background-color: ${(props) => props.theme.color.face.contrast};
      border-color: ${(props) => props.theme.color.face.contrast};
    }
  }
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  target?: string
  to: string
  children: any
  className?: string
}

const ButtonWhite: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  isCustom = false,
  target,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonWhite
