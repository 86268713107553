/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { motion } from 'framer-motion'
import { useMedia } from 'react-use'

// Components
import CustomLink from 'components/shared/CustomLink'

// SVG
import Logo from 'img/logo.inline.svg'
import ArrowRight from 'img/arrow-right.svg'
import ButtonOutline from 'components/elements/ButtonOutline'

const StyledHeader = styled(motion.nav)`
  background-color: ${(props) => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 90px;
  z-index: 3;

  @media (min-width: 2000px) {
    & .container-lg {
      max-width: 1600px;
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    width: 165px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.dark};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({ isSticky, setIsScrollable }) => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader
      initial={{ boxShadow: 'none' }}
      animate={
        isSticky
          ? {
              borderBottom: '1px solid #C0B6A5',
            }
          : { borderBottom: 'none' }
      }
    >
      <Container className="container-lg">
        <Brand to="/">
          <Logo />
        </Brand>

        <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
        <MenuDesktop fields={fields} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 70% !important;
      margin: 0 auto;
    }

    & li:not(:last-child) {
      padding: 0;
      border-bottom: 1px solid #f1f1f1;
      @media (max-width: 991px) {
        width: 100%;
        text-align: left;
      }

      & a {
        font-size: ${(props) => props.theme.font.size[24]};
        display: block;
        padding: 10px 0;
      }

      &:last-child {
        display: flex;
        justify-content: flex-start;
        border: unset;
        @media (min-width: 991px) {
          justify-content: center;
        }

        & > a {
          padding: 1.3rem 1rem !important;
          min-width: 240px;
          margin-top: 20px;
          &:after {
            padding-left: 20px;
          }
        }
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

interface MenuMobileProps {
  fields: any
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isBurgerMenu = useMedia('(max-width: 1199px)')

  return (
    <div className="d-block d-xl-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
          {isBurgerMenu && (
            <div
              style={{ width: '70%', zIndex: 99 }}
              className="d-flex justify-content-start justify-content-lg-center mx-auto position-relative"
            >
              <FaqButton to="/afspraak-maken/" className="text-uppercase">
                Afspraak maken
              </FaqButton>
            </div>
          )}
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

interface MenuDesktopProps {
  fields: any
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-xl-flex justify-content-end">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  font-family: ${(props) => props.theme.font.family.main};
  font-weight: ${(props) => props.theme.font.weight.s};
  & > a {
    text-transform: uppercase;
    color: ${(props) => props.theme.color.text.dark};
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 300;

    &[aria-current] {
      color: ${(props) => props.theme.color.text.light};
      font-weight: 400 !important;
      overflow: hidden !important;

      @media (min-width: 1200px) {
        color: ${(props) => props.theme.color.face.secondary};
      }
    }
  }

  &:last-child > a {
    background-color: ${(props) => props.theme.color.face.light};
    border: 1px solid ${(props) => props.theme.color.text.main};
    padding: 0.75rem 1.3rem !important;
    letter-spacing: 1px !important;
    font-size: 14px !important;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
    border-radius: 5px;
    font-weight: 700;
    margin-right: 0 !important;

    &:before {
      visibility: hidden;
    }

    &:hover {
      background-color: ${(props) => props.theme.color.face.secondary};
      border: 1px solid ${(props) => props.theme.color.face.secondary};
    }

    &:after {
      content: url(${ArrowRight});
      padding-left: ${(props) => props.theme.font.spacing.l};
      @media (max-width: 575px) {
        content: ' ' !important;
      }
    }
  }
`

const LinkContainer = styled(motion.div)`
  & a {
    color: #000;
    font-weight: 300;

    &[aria-current] {
      color: ${(props) => props.theme.color.text.dark};
      overflow: hidden !important;
      @media (min-width: 1200px) {
        color: ${(props) => props.theme.color.face.secondary};
      }
    }
  }
`

const StyledCustomLink = styled(CustomLink)`
  position: relative;
  letter-spacing: 1px;

  @media (min-width: 1200px) {
    margin-right: 1.7rem;
  }
`

const HoverableItem = styled(motion.li)`
  font-family: ${(props) => props.theme.font.family.main};
  font-weight: ${(props) => props.theme.font.weight.s};

  & > a {
    text-transform: lowercase;
    color: ${(props) => props.theme.color.text.dark};
    letter-spacing: ${(props) => props.theme.font.spacing.s};

    &[aria-current] {
      color: ${(props) => props.theme.color.text.light};
      font-weight: 400 !important;
      overflow: hidden !important;
      @media (min-width: 1200px) {
        color: ${(props) => props.theme.color.face.secondary};
      }
    }
  }

  &:last-child {
    background-color: ${(props) => props.theme.color.face.light};
    transition: all 0.2s ease-in-out;

    & > a {
      border: 1px solid ${(props) => props.theme.color.text.main};
      padding: 0.75rem 1rem !important;
      border-radius: 5px;
    }

    &:before {
      visibility: hidden;
    }

    &:hover {
      background-color: ${(props) => props.theme.color.face.secondary};
      border: 1px solid ${(props) => props.theme.color.face.secondary};
    }

    &:after {
      content: url(${ArrowRight});
      padding-left: ${(props) => props.theme.font.spacing.l};
      @media (max-width: 575px) {
        content: ' ' !important;
      }
    }
  }

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const StyledButtonOutline = styled(ButtonOutline)`
  @media (min-width: 992px) {
    width: 140px;
  }
`

const SubMenuWrapper = styled(motion.div)`
  position: absolute;
  top: 90px;
  background: ${(props) => props.theme.color.text.light};
  max-width: 320px;
  height: auto;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
`

const HoverableSubItem = styled(motion.div)`
  & a {
    text-transform: uppercase;

    &:hover {
      font-weight: ${(props) => props.theme.font.weight.m};
    }

    @media (max-width: 1200px) {
      font-size: ${(props) => props.theme.font.size[14]} !important;
    }
  }
  @media (max-width: 991px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
      border: none;
    }
  }
`

const SubMenuCustomLink = styled(CustomLink)`
  @media (max-width: 991px) {
    font-size: 14px !important;
  }
`

const SubMenuContainer = styled(motion.div)`
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const FaqButton = styled(Link)`
  background-color: ${(props) => props.theme.color.face.contrast};
  border: 1px solid ${(props) => props.theme.color.text.contrast};
  padding: 11px 15px !important;
  font-size: 17px;
  transition: background-color 0.2s ease-in-out;
  min-width: 240px;
  font-weight: 200;
  color: ${(props) => props.theme.color.text.dark};
  text-align: center;
  color: ${(props) => props.theme.color.text.light};
  font-weight: bold;

  &:before {
    visibility: hidden;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.face.secondary};
    border: 1px solid ${(props) => props.theme.color.face.secondary};
  }
  &:after {
    content: url(${ArrowRight});
    padding-left: 20px;
  }
`

interface SubMenuProps {
  fields: any
}

interface MenuProps {
  fields: any
}

const SubMenu: React.FC<SubMenuProps> = ({ fields }) =>
  fields.submenu.map((edge: any, index: number) => (
    <HoverableSubItem
      className="col-12 d-flex justify-content-center mb-3"
      key={index}
    >
      <motion.div className="d-flex w-100 justify-content-start align-items-center">
        <div>
          <SubMenuCustomLink to={edge.link.url}>
            {edge.link.title}
          </SubMenuCustomLink>
        </div>
      </motion.div>
    </HoverableSubItem>
  ))

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const isDesktopMenu = useMedia('(min-width: 1200px)')
  const isMobileMenu = useMedia('(max-width: 1199px)')

  const [isHover, toggleHover] = useState(false)
  const [isOpen, toggleOpen] = useState(false)
  const [isTapped, toggleTap] = useState(false)

  const toggleTapMenu = () => {
    toggleTap(!isTapped)
    toggleOpen(!isOpen)
  }

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      display: 'flex',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  const tapMenuAnimate = {
    enter: {
      opacity: 1,
      height: 'auto',
      width: '100%',
      transition: {
        duration: 0.1,
      },
      display: 'flex',
    },
    exit: {
      height: 0,
      opacity: 0,
      width: 0,
      transition: {
        duration: 0.1,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <>
      {fields.header.menuHeader.map((edge: any, index: number) => {
        if (edge.submenu) {
          return (
            <HoverableItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="pr-1"
              onMouseEnter={isDesktopMenu ? () => toggleHover(true) : null}
              onMouseLeave={isDesktopMenu ? () => toggleHover(false) : null}
            >
              <SubMenuWrapper
                initial="exit"
                animate={isHover ? 'enter' : 'exit'}
                variants={subMenuAnimate}
                className="container px-3 py-2 flex-column"
              >
                <div className="row">
                  <div className="position-relative">
                    <SubMenu fields={edge} />
                  </div>
                </div>
              </SubMenuWrapper>
              <LinkContainer className="d-flex justify-content-center justify-content-xl-start align-items-center position-relative text-uppercase">
                <StyledCustomLink to={edge.link.url} className="py-3">
                  {edge.link.title}
                </StyledCustomLink>
                {isMobileMenu && (
                  <motion.span
                    className="pl-5 font-weight-bold"
                    onTapStart={isMobileMenu ? toggleTapMenu : null}
                    onTapCancel={isMobileMenu ? toggleTapMenu : null}
                  >
                    {isOpen ? '-' : '+'}
                  </motion.span>
                )}
              </LinkContainer>
              <SubMenuContainer
                className="d-xl-none d-block"
                initial="exit"
                animate={isTapped ? 'enter' : 'exit'}
                variants={tapMenuAnimate}
              >
                <SubMenu fields={edge} />
              </SubMenuContainer>
            </HoverableItem>
          )
        }
        if (fields.header.menuHeader.length - 1 === index) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index} className="pr-1">
              <StyledButtonOutline
                to={edge.link.url}
                className="py-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mr-2">{edge.link.title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.95 4.48643C13.95 4.21289 13.7098 3.99103 13.4141 3.99103L0.55666 3.99103C0.260901 3.99103 0.021018 4.21289 0.021018 4.48643C0.021018 4.75998 0.261153 4.98201 0.55666 4.98201L13.4141 4.98201C13.7098 4.98201 13.95 4.75998 13.95 4.48643Z"
                    fill="#505050"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.5075 8.80108L13.7934 4.8373C14.0024 4.64401 14.0024 4.32988 13.7934 4.13664L9.5075 0.172866C9.29857 -0.0204311 8.95893 -0.0204311 8.74993 0.172866C8.541 0.366104 8.541 0.680233 8.74993 0.873472L12.6566 4.48642L8.74899 8.09954C8.64503 8.19663 8.59254 8.32344 8.59254 8.45043C8.59254 8.57724 8.64503 8.70399 8.74993 8.80108C8.95912 8.99432 9.29857 8.99432 9.5075 8.80108Z"
                    fill="#505050"
                  />
                </svg>
              </StyledButtonOutline>
            </Item>
          )
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index} className="pr-1">
            <StyledCustomLink to={edge.link.url} className="py-3">
              {edge.link.title}
            </StyledCustomLink>
          </Item>
        )
      })}
    </>
  )
}

export default Header
