import React from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

const isPartiallyActive = (data: any) => {
  let { isPartiallyCurrent } = data

  // if 'to' location is '/' and current page is home than set partially current
  if (data.location.pathname === '/' && data.href === '/home/') {
    isPartiallyCurrent = true
  }

  const hashSplit = data.href.split('#')

  // if 'to' has hash than custom check partially current
  if (hashSplit[1]) {
    hashSplit[0] = hashSplit[0].replace('/#', '')
    hashSplit[0] = hashSplit[0].replace('#', '')

    if (hashSplit[0] === data.location.pathname) {
      isPartiallyCurrent = true
    }
  }

  if (data.location.pathname !== '/' && data.href === '/') {
    isPartiallyCurrent = false
  }

  return isPartiallyCurrent ? { 'aria-current': `page` } : {}
}

const MotionLink = motion(Link)

interface CustomLinkProps {
  to: string
  className?: string
  target?: string
  rel?: string
  children: any
}

const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  className = '',
  rel = '',
  children,
  target,
}) => (
  <MotionLink
    getProps={isPartiallyActive}
    to={to}
    className={className}
    target={target}
    rel={rel}
  >
    {children}
  </MotionLink>
)

export default CustomLink
