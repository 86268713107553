import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Image2Props {
  className?: string
  image: any
  alt?: string
  loading: 'eager' | 'lazy'
}

const Image2: React.FC<Image2Props> = ({
  className = '',
  image,
  alt = '',
  loading = 'lazy',
}) => {
  if (!image?.localFile?.childImageSharp?.gatsbyImageData) {
    return null
  }

  return (
    <GatsbyImage
      className={className}
      image={image?.localFile?.childImageSharp?.gatsbyImageData}
      alt={alt}
      loading={loading}
    />
  )
}

export default Image2
