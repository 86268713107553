import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'
import { motion } from 'framer-motion'

const StyledButton = styled(motion.span)`
  display: block;
  width: 245px;
  text-align: center;
  & > a,
  & > button {
    font-family: ${(props) => props.theme.font.family.secondary};
    border: 1px solid ${(props) => props.theme.color.face.dark};
    color: ${(props) => props.theme.color.text.dark};
    font-size: ${(props) => props.theme.font.size.base};
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 15px;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      color: ${(props) => props.theme.color.text.light};
      background-color: ${(props) => props.theme.color.face.dark};
    }
  }
`

interface ButtonOutlineProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonOutline: React.FC<ButtonOutlineProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonOutline
