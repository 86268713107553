import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Components
import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'

// CSS
import 'styles/all.css'
import CustomLink from 'components/shared/CustomLink'

// Theme
import Theme from 'styles/Global'

const Content = styled.div<{ isSticky?: boolean }>`
  padding-top: 0;
`

const StyledCustomLink = styled(CustomLink)`
  background: ${(props) => props.theme.color.face.contrast};
  position: fixed;
  font-weight: ${(props) => props.theme.font.weight.m};
  color: ${(props) => props.theme.color.text.light};
  top: 0;
  margin: auto;
  right: 25px;
  height: 50px;
  z-index: 1;
  transform: rotate(-90deg);
  transform-origin: center right;
  white-space: nowrap;
  bottom: 0;
  padding: 1rem;
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-top-right-radius: 30px;

  &:hover {
    color: ${(props) => props.theme.color.text.light};
    right: 20px;
    padding-bottom: 22.2px;
    background-color: ${(props) => props.theme.color.text.main};
  }

  @media (max-width: 991px) {
    display: none !important;
  }
`

interface LayoutProps {
  children: any
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const handleScroll = () => {
    if (typeof window !== `undefined`) {
      setSticky(window.scrollY > 0)
      setIsVisible(window.scrollY > 600)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
      <Content isSticky={isSticky}>
        <StyledCustomLink
          to="/afspraak-maken/"
          className={`${isVisible ? 'd-flex' : 'd-none'} text-center px-4`}
        >
          Maak een afspraak
        </StyledCustomLink>
        {children}
      </Content>
      <Footer />
    </Theme>
  )
}

export default Layout
