import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size['30']};
  font-weight: ${(props) => props.theme.font.weight.l};
  text-transform: uppercase;
  margin-bottom: 0;
`

const StyledDiv = styled.div`
  width: 82%;
  height: 2px;
`

interface TitleDefaultProps {
  className?: string
  children: any
  isLight?: boolean
}

const TitleDefault: React.FC<TitleDefaultProps> = ({
  className = '',
  children,
  isLight = false,
}) => (
  <div className="d-flex justify-content-between align-items-center w-100">
    <Title
      className={`${className}${isLight ? 'text-white' : 'text-secondary'} `}
    >
      {children}
    </Title>
    <StyledDiv className={`${isLight ? 'bg-contrast' : 'bg-secondary'}`} />
  </div>
)

export default TitleDefault
